import React from "react";
import Typography from "@material-ui/core/Typography/Typography";
import Container from "@material-ui/core/Container/Container";
import GridList from "@material-ui/core/GridList/GridList";
import GridListTile from "@material-ui/core/GridListTile/GridListTile";

class CollectionView extends React.Component {

   getCollectionImage(image) {
      return image
         ? this.props.nameToFluidPhoto[image].src
         : "n/a"
   }

   render() {
      return (
         <div>
            <Container maxWidth="lg">
               <Typography component="h1" variant="h2" align="left" color="textPrimary" gutterBottom>
                  {this.props.collection.name}
               </Typography>

               <div>
                  <GridList cellHeight={300} cols={3}>
                     {this.props.collection.order && this.props.collection.order.map(image => (
                        <GridListTile key={image} cols={1}>
                           <img src={this.getCollectionImage(image)} alt={image} />
                        </GridListTile>
                     ))}
                  </GridList>
               </div>

            </Container>
         </div>
      )
   }
}

export default CollectionView;