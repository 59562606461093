import React, {Component} from "react"
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Img from "gatsby-image";
import DeleteIcon from '@material-ui/icons/Delete';
import { withSnackbar } from 'notistack';


class UpdatePhotos extends Component {
    deletePhoto = (file) => () => {
        fetch('https://h182scn0le.execute-api.us-west-2.amazonaws.com/prod/photo', {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.idToken
            },
            body: JSON.stringify({file})
        }).then(res => {
            this.props.onPhotoDeleted(file);
            this.props.enqueueSnackbar(`Successfully deleted ${file}.`);
        }).catch(err => {
            this.props.enqueueSnackbar(`Error deleting ${file}.`);
        });
    };


    render() {

        const imageCards = this.props.photoOrder.map((file) =>
            <Card key={file} style={{maxWidth: '250px', minWidth: '250px', marginBottom: '5px'}}>
                <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                        {file}
                    </Typography>
                    <Img
                        key={file}
                        alt={file}
                        fluid={this.props.nameToFluidPhoto[file]}
                    />
                </CardContent>
                <CardActions>
                    <Button size="small"
                         onClick={this.deletePhoto(file).bind(this)}>
                        <DeleteIcon/>
                    </Button>
                </CardActions>
            </Card>
        );

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {imageCards}
            </div>
        )
    }
}


export default withSnackbar(UpdatePhotos);
