import React, {Component} from "react"
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import Typography from "@material-ui/core/Typography/Typography";
import SaveIcon from '@material-ui/icons/Save';
import Button from "@material-ui/core/Button/Button";

export default class EditMarkdownPage extends Component {
   constructor(props) {
      super(props);

      this.state = {
         mdeValue: undefined
      }
   }

   handleChange = value => {
      this.setState({mdeValue: value});
   };

   saveChanges() {
      this.props.savePageContent(this.props.originalPageMarkdown, this.state.mdeValue);
   }

   static capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
   }

   render() {
      return (
         <div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
               <Typography variant="h4" gutterBottom component="h2">
                  {EditMarkdownPage.capitalize(this.props.page)}
               </Typography>

               <Button style={{marginLeft: '15px'}}
                       onClick={this.saveChanges.bind(this)}>
                  <SaveIcon/>
               </Button>
            </div>
            <SimpleMDE
               key={`markdown-editor-for-page-${this.props.page}`}
               onChange={this.handleChange}
               value={this.props.originalPageMarkdown}
               options={{
                  autofocus: true,
                  spellChecker: false
                  // etc.
               }}
            />
         </div>
      )
   }
}
