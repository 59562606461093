import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";

const styles = theme => ({
   main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))] : {
         width: 400,
         marginLeft: 'auto',
         marginRight: 'auto',
      },
   },
   paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      marginTop: theme.spacing(3),
   },
});

class SignIn extends Component {
   constructor(props) {
      super(props)

      this.state = {
         email: undefined,
         pass: undefined
      }
   }

   onEmailChanged(event) {
      this.setState({email: event.target.value});
   }

   onPassChanged(event) {
      this.setState({pass: event.target.value});
   }

   onLoginClicked() {
      const {email, pass} = this.state;

      var userData = {
         Username : email,
         Pool : this.props.userPool
      };

      var cognitoUser = new CognitoUser(userData);

      var authenticationData = {
         Username : email,
         Password : pass
      };
      var authenticationDetails = new AuthenticationDetails(authenticationData);

      cognitoUser.authenticateUser(authenticationDetails, {
         onSuccess:  (result) => {
            var accessToken = result.getAccessToken().getJwtToken();

            /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer*/
            var idToken = result.idToken.jwtToken;
            this.props.onLoggedIn({
               accessToken,
               idToken
            });
         },

         onFailure: function(err) {
            alert(err.message);
         },

         newPasswordRequired: function(userAttributes, requiredAttributes) {
            // User was signed up by an admin and must provide new
            // password and required attributes, if any, to complete
            // authentication.

            // userAttributes: object, which is the user's current profile. It will list all attributes that are associated with the user.
            // Required attributes according to schema, which don’t have any values yet, will have blank values.
            // requiredAttributes: list of attributes that must be set by the user along with new password to complete the sign-in.


            // Get these details and call
            // newPassword: password that user has given
            // attributesData: object with key as attribute name and value that the user has given.
            const newPassword = "the new password would go here";
            const email = "the email goes here";
            cognitoUser.completeNewPasswordChallenge(newPassword, {email}, this)
         }

      });
   }

   render() {
      const {classes} = this.props;

      return (
         <main className={classes.main}>
            <CssBaseline />
            <Paper className={classes.paper}>
               <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
               </Avatar>
               <Typography component="h1" variant="h5">
                  Sign in
               </Typography>
               <div className={classes.form}>
                  <FormControl margin="normal" required fullWidth>
                     <InputLabel htmlFor="email">Email Address</InputLabel>
                     <Input id="email" name="email" autoComplete="email" autoFocus
                            onChange={this.onEmailChanged.bind(this)}/>
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                     <InputLabel htmlFor="password">Password</InputLabel>
                     <Input name="password" type="password" id="password" autoComplete="current-password"
                     onChange={this.onPassChanged.bind(this)}/>
                  </FormControl>
                  <FormControlLabel
                     control={<Checkbox value="remember" color="primary" />}
                     label="Remember me"
                  />
                  <Button
                     type="submit"
                     fullWidth
                     variant="contained"
                     color="primary"
                     className={classes.submit}
                     onClick={this.onLoginClicked.bind(this)}
                  >
                     Sign in
                  </Button>
               </div>
            </Paper>
         </main>
      );
   }
}


SignIn.propTypes = {
   classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);