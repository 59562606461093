import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';

class UploadImagesDialog extends React.Component {
    state = {
        open: true,
    };

    handleClose = () => {
        this.props.onFinishUploadPhotos();
    };

    componentDidMount() {
        const {imageFiles} = this.props;

        this.uploadImages(imageFiles)
            .then(success => {
                this.props.onFinishUploadPhotos();
            })
            .catch(err => {
            })
    }

    async uploadImages(imageFiles) {
        const numImages = imageFiles.length;
        let currentImageUploading = 1;

        for (const imageFile of imageFiles) {
            const currentImageName = imageFile.name;
            const uploadPercentComplete = ((currentImageUploading - 1) / numImages) * 100;

            this.setState({
                numImages,
                currentImageUploading,
                currentImageName,
                uploadPercentComplete
            });

            await this.uploadImage(imageFile);
            this.props.onImageUploadedSuccessfully(currentImageName);
            currentImageUploading++;
        }
    }

    async uploadImage(file) {
        const data = await this.getFileData(file);

        return await fetch("https://h182scn0le.execute-api.us-west-2.amazonaws.com/prod/photo", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.idToken
            },
            body: JSON.stringify({
                image: data,
                name: file.name,
                size: file.size,
                type: file.type
            })
        });
    }

    getFileData(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader(file);
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = () => {
                reject("err");
            };
        });
    }

    render() {
        const {numImages, currentImageUploading, currentImageName, uploadPercentComplete} = this.state;
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Uploading Images..."}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`${currentImageUploading}/${numImages} ${currentImageName}`}
                        </DialogContentText>
                        <LinearProgress variant="determinate" value={uploadPercentComplete}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default UploadImagesDialog;