import React from "react";
import TextField from '@material-ui/core/TextField';
import GridListTile from "@material-ui/core/GridListTile/GridListTile";
import GridList from "@material-ui/core/GridList/GridList";
import _ from "lodash";
import Typography from "@material-ui/core/Typography/Typography";
import CheckIcon from '@material-ui/icons/CheckCircle';
import Button from "@material-ui/core/Button/Button";

class EditCollection extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         selectedImages: EditCollection.orderPropAsObject(props.collection.order),
         originalName: props.collection.name || '',
         collectionName: props.collection.name || '',
         collectionDescription: props.collection.description || ''
      }
   }

   static orderPropAsObject(order) {
      return order
         ? order.reduce((obj, file) => {
            obj[file] = true;
            return obj;
         }, {})
         : {};
   }

   onImageClicked = (image) => () => {
      const selectedImages = _.clone(this.state.selectedImages);

      if (selectedImages[image]) {
         delete selectedImages[image];
      }
      else {
         selectedImages[image] = true;
      }

      this.setState({selectedImages});
   };

   isImageSelected(image) {
      return this.state.selectedImages[image];
   }

   onTextFieldUpdated = (field) => (event) => {
      this.setState({[field]: event.target.value});
   }

   onSaveCollectionClicked() {
      if (!this.state.selectedImages || !this.state.collectionName) {
         alert("cannot save");
         return;
      }

      const body = JSON.stringify({
         name: this.state.collectionName.trim(),
         originalName: this.state.originalName,
         description: this.state.collectionDescription,
         order: _.keys(this.state.selectedImages)
      });

      fetch(' https://h182scn0le.execute-api.us-west-2.amazonaws.com/prod/collections', {
         method: 'PUT',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.props.idToken
         },
         body
      }).then((response) => response.text())
         .then(() => {
            alert("successfully saved!");
            this.props.onCollectionSaved({
               name: this.state.collectionName,
               description: this.state.collectionDescription,
               order: _.keys(this.state.selectedImages)
            })
         })
         .catch(err => {
            console.log("err uh oh " + err);
            alert(JSON.stringify(err, null, 2));
         });
   }

   render() {
      return (
         <div>
            <div style={{display: 'flex', flexDirection: "column"}}>
               <div style={{display: 'flex', flexDirection: "column"}}>
                  <div style={{display: 'flex', flexDirection: "row"}}>
                     <TextField
                        id="standard-name"
                        label="Collection Name"
                        value={this.state.collectionName}
                        onChange={this.onTextFieldUpdated('collectionName').bind(this)}
                        margin="normal"
                     />
                  </div>
                  <div style={{display: 'flex', flexDirection: "row"}}>
                     <TextField
                        id="standard-name"
                        label="Collection Description"
                        value={this.state.collectionDescription}
                        onChange={this.onTextFieldUpdated('collectionDescription').bind(this)}
                        margin="normal"
                     />
                  </div>
               </div>

               <Typography gutterBottom variant="h5" component="h2" style={{paddingTop: "50px"}}>
                  Select Collection Images
               </Typography>

               <GridList cellHeight={300} cols={3}>
                  {_.keys(this.props.nameToFluidPhoto).map(key => {
                     return <GridListTile key={key} cols={1}>
                        <div className={`collection-photo`} onClick={this.onImageClicked(key).bind(this)}>
                           <img src={this.props.nameToFluidPhoto[key].src} alt={key}/>
                           <CheckIcon className={this.isImageSelected(key) ? 'selected-photo-icon' : ''}/>
                        </div>
                     </GridListTile>
                  })}
               </GridList>

               <Button
                  onClick={this.onSaveCollectionClicked.bind(this)}
                  variant="outlined"
                  color="inherit"
                  style={{marginTop: "15px"}}
                  size="small">
                  Save collection
               </Button>
            </div>
         </div>
      );
   }
}

export default EditCollection;
