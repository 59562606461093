import React from "react";
import AllCollectionsView from "./all-collections-view";
import CollectionsMenu from "./collections-menu";
import CollectionView from "./collection-view";
import EditCollection from "./edit-collection";

export default class Collections extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         mode: 'allCollections'
      }
   }

   onViewCollectionClicked(collection) {
      this.setState({
         mode: 'viewCollection',
         collection
      })
   }

   onBackToAllCollectionsClicked() {
      this.setState({
         mode: 'allCollections'
      });
   }

   onNewCollectionClicked() {
      this.setState({
         mode: 'editCollection',
         collection: {}
      });
   }

   onEditCollectionClicked(collection) {
      this.setState({
         mode: 'editCollection',
         collection
      });
   }


   onDeleteCollectionClicked(collection) {
      fetch(' https://h182scn0le.execute-api.us-west-2.amazonaws.com/prod/collections', {
         method: 'DELETE',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.props.idToken
         },
         body: JSON.stringify({name: collection.name})
      }).then((response) => response.text())
         .then(() => {
            alert("successfully deleted " + collection.name);
            this.props.onCollectionDeleted(collection.name)
         })
         .catch(err => {
            console.log("err uh oh " + err);
            alert(JSON.stringify(err, null, 2));
         });
   }

   render() {

      return (
         <div>
            <CollectionsMenu onBackToAllCollectionsClicked={this.onBackToAllCollectionsClicked.bind(this)}/>
            {
               this.state.mode === 'allCollections' && this.props.collections && this.props.nameToFluidPhoto &&
               <AllCollectionsView
                  collections={this.props.collections}
                  nameToFluidPhoto={this.props.nameToFluidPhoto}
                  onViewCollectionClicked={this.onViewCollectionClicked.bind(this)}
                  onNewCollectionClicked={this.onNewCollectionClicked.bind(this)}
                  onEditCollectionClicked={this.onEditCollectionClicked.bind(this)}
                  onDeleteCollectionClicked={this.onDeleteCollectionClicked.bind(this)}
               />
            }
            {
               this.state.mode === 'viewCollection' && <CollectionView
                  collection={this.state.collection}
                  nameToFluidPhoto={this.props.nameToFluidPhoto}
               />
            }
            {
               this.state.mode === 'editCollection' && <EditCollection
                  nameToFluidPhoto={this.props.nameToFluidPhoto}
                  idToken={this.props.idToken}
                  collection={this.state.collection}
                  onCollectionSaved={this.props.onCollectionSaved}
               />
            }
         </div>
      )
   }
}
