import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AddIcon from '@material-ui/icons/Add';


const useStyles = makeStyles(theme => ({
   icon: {
      marginRight: theme.spacing(2),
   },
   heroContent: {
      //backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
   },
   heroButtons: {
      marginTop: theme.spacing(4),
   },
   cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
   },
   card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
   },
   cardMedia: {
      paddingTop: '56.25%', // 16:9
   },
   cardContent: {
      flexGrow: 1,
   },
   footer: {
      //backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
   },
}));


export default function AllCollectionsView(props) {
   const classes = useStyles();

   function getCollectionImage(collection) {
      return collection.order && collection.order.length
         ? props.nameToFluidPhoto[collection.order[0]].src
         : "n/a"
   }

   const onViewCollectionClicked = (collection) => () => {
      props.onViewCollectionClicked(collection);
   };

   const onEditCollectionClicked = (collection) => () => {
      props.onEditCollectionClicked(collection);
   };

   const onDeleteCollectionClicked = (collection) => () => {
      props.onDeleteCollectionClicked(collection);
   };


   return (
      <React.Fragment>
         <CssBaseline/>
         <main>

            <Container className={classes.cardGrid} maxWidth="md">
               {/* End hero unit */}
               <Grid container spacing={4}>
                  {props.collections.map(collection => (
                     <Grid item key={collection.name} xs={12} sm={6} md={4}>
                        <Card className={classes.card}>
                           <CardMedia
                              className={classes.cardMedia}
                              image={getCollectionImage(collection)}
                              title="Image title"
                           />
                           <CardContent className={classes.cardContent}>
                              <Typography gutterBottom variant="h5" component="h2">
                                 {collection.name}
                              </Typography>
                              <Typography>
                                 {collection.description}
                              </Typography>
                           </CardContent>
                           <CardActions>
                              <Button size="small" color="default" onClick={onViewCollectionClicked(collection)}>
                                 View
                              </Button>
                              <Button size="small" color="primary" onClick={onEditCollectionClicked(collection)}>
                                 Edit
                              </Button>
                              <Button size="small" color="secondary" onClick={onDeleteCollectionClicked(collection)}>
                                 Delete
                              </Button>
                           </CardActions>
                        </Card>
                     </Grid>
                  ))}

                  <Grid item key={'new collection'} xs={12} sm={6} md={4}>
                     <Card onClick={props.onNewCollectionClicked} className={'new-collection'}>

                        <CardContent className={classes.cardContent}>
                           <Typography gutterBottom variant="h5" component="h2">
                              new collection
                           </Typography>
                           <AddIcon/>
                        </CardContent>
                     </Card>
                  </Grid>
               </Grid>
            </Container>
         </main>
      </React.Fragment>
   );
}