import React, {Component} from "react"
import Img from "gatsby-image";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Button from "@material-ui/core/Button";

export default class DragDropPhotos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: props.photoOrder.map((file) => {
                return {
                    id: file, content:
                        <div>
                            <p>{file}</p>
                            <Img
                                key={file}
                                alt={file}
                                fluid={props.nameToFluidPhoto[file]}
                            />
                        </div>
                }
            })
        }
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items,
        });
    }

    callUpdate() {
        fetch('https://h182scn0le.execute-api.us-west-2.amazonaws.com/prod/site-config', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.idToken
            },
            body: JSON.stringify({
                "list": this.state.items.map((item) => item.id)
            })
        }).then(res => {
            alert(JSON.stringify(res, null, 2));
        }).catch(err => {
            alert(JSON.stringify(err, null, 2));
        });
    }

    onAddPhotosClicked(files) {
        this.setState({uploadingImages: true, imagesToUpload: files});
    }

    onCancelUploadPhotos() {
        this.setState({uploadingImages: false});
    }

    render() {
        return (
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>

                    <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {this.state.items.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    {item.content}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                <Button
                    onClick={this.callUpdate.bind(this)}
                    variant="outlined"
                    color="inherit"
                    style={{marginTop: "10px"}}
                    size="small">
                    Save Photo Order
                </Button>
            </div>
        )
    }
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: 250,
});