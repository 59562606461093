import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import EditPhotos from "./edit-photos";
import Button from "@material-ui/core/Button/Button";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PhotoIcon from '@material-ui/icons/Photo';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import EditMarkdownPage from "./edit-markdown-page";
import Collections from "./collections/collections";
import _ from 'lodash';

const drawerWidth = 240;

const styles = theme => ({
   root: {
      display: 'flex',
   },
   toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
   },
   toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
   },
   appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.leavingScreen,
      }),
   },
   appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
   },
   menuButton: {
      marginLeft: 12,
      marginRight: 36,
   },
   menuButtonHidden: {
      display: 'none',
   },
   title: {
      flexGrow: 1,
   },
   drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
   },
   drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
         width: theme.spacing(9),
      },
   },
   appBarSpacer: theme.mixins.toolbar,
   content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      height: '100vh',
      overflow: 'auto',
   },
   chartContainer: {
      marginLeft: -22,
   },
   tableContainer: {
      height: 320,
   },
   h5: {
      marginBottom: theme.spacing(2),
   },
});

class Dashboard extends React.Component {

   state = {
      open: true,
      page: 'collections',
      originalMarkdown: {}
   };

   handleDrawerOpen = () => {
      this.setState({open: true});
   };

   handleDrawerClose = () => {
      this.setState({open: false});
   };

   goToPage = (page) => () => {
      this.setState({page});
   };

   extractMarkdownFromResponse(data) {
      const times = 2;
      for (let i = 0; i < times; i++) {
         data = data.substring(data.indexOf('---\n') + 4);
      }
      return data;
   }

   componentDidMount() {
      const fetchContactPage = this.fetchPageContent('contact');
      const fetchHomePage = this.fetchPageContent('home');
      const fetchAboutPage = this.fetchPageContent('about');
      const fetchCollections = this.fetchCollections();

      Promise.all([fetchContactPage, fetchHomePage, fetchCollections, fetchAboutPage])
         .then(result => {
            const [contactPageMarkdown, homePageMarkdown, collectionsResult, aboutMarkdown] = result;

            this.setState({
               originalMarkdown: {
                  contact: contactPageMarkdown,
                  home: homePageMarkdown,
                  about: aboutMarkdown
               },
               collections: JSON.parse(collectionsResult)
            });
         })
         .catch(err => alert(err));
   }

   //we need to either add it to the array of collections or
   //update it in place
   onCollectionSaved(savedCollection) {
      const previousVersion = _.find(this.state.collections, (collection) => {
         return collection.name === savedCollection.name;
      });

      if (previousVersion) {
         this.setState({
            collections: this.state.collections.map(collection =>
               collection.name === savedCollection.name
                  ? savedCollection
                  : collection
            )
         });
      }
      else {
         this.setState({collections: this.state.collections.concat(savedCollection)});
      }
   }


   fetchCollections() {
      return fetch(`https://h182scn0le.execute-api.us-west-2.amazonaws.com/prod/collections`, {
         method: 'GET',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.props.idToken
         }
      }).then((response) => response.text());
   }

   fetchPageContent(page) {
      return fetch(`https://h182scn0le.execute-api.us-west-2.amazonaws.com/prod/page-markdown?page=${page}`, {
         method: 'GET',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.props.idToken
         }
      }).then((response) => response.text())
         .then((pageMarkdown) => this.extractMarkdownFromResponse(pageMarkdown));
   }

   savePageContent = (page) => (originalContent, newContent) => {
      if (!newContent || originalContent === newContent) {
         alert("Content did not change");
         return;
      }

      fetch('https://h182scn0le.execute-api.us-west-2.amazonaws.com/prod/page-markdown', {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.props.idToken
         },
         body: JSON.stringify({
            page,
            content: newContent
         })
      }).then((response) => response.text())
         .then(() => {
            alert("successfully saved!");
            const newOriginalMarkdown = {[page]: newContent};
            this.setState({originalMarkdown: Object.assign(this.state.originalMarkdown, newOriginalMarkdown)});
         })
         .catch(err => {
            console.log("err uh oh " + err);
            alert(JSON.stringify(err, null, 2));
         });
   };

   getPageEditor = (page) => {
      return <EditMarkdownPage
         page={page}
         originalPageMarkdown={this.state.originalMarkdown[page]}
         savePageContent={this.savePageContent(page).bind(this)}
      />;
   }

   onCollectionDeleted(name) {
      this.setState({
         collections: _.filter(this.state.collections, (collection) =>
            collection.name !== name
         )
      });
   }

   render() {
      const {classes} = this.props;

      const pageToComponent = {
         'photos': <EditPhotos
            idToken={this.props.idToken}
            photoOrder={this.props.photoOrder}
            nameToFluidPhoto={this.props.nameToFluidPhoto}
            onPhotoDeleted={this.props.onPhotoDeleted}
         />,
         'home': this.getPageEditor('home'),
         'contact': this.getPageEditor('contact'),
         'about': this.getPageEditor('about'),
         'collections': <Collections
            collections={this.state.collections}
            nameToFluidPhoto={this.props.nameToFluidPhoto}
            idToken={this.props.idToken}
            onCollectionSaved={this.onCollectionSaved.bind(this)}
            onCollectionDeleted={this.onCollectionDeleted.bind(this)}
         />
      };

      return (
         <div className={classes.root}>
            <CssBaseline/>
            <AppBar
               position="absolute"
               className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
            >
               <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                  <IconButton
                     color="inherit"
                     aria-label="Open drawer"
                     onClick={this.handleDrawerOpen}
                     className={classNames(
                        classes.menuButton,
                        this.state.open && classes.menuButtonHidden,
                     )}
                  >
                     <MenuIcon/>
                  </IconButton>
                  <Typography
                     component="h1"
                     variant="h6"
                     color="inherit"
                     noWrap
                     className={classes.title}
                  >
                     Admin Page
                  </Typography>
                  <Button
                     onClick={this.props.onLogoutClicked}
                     variant="outlined"
                     color="inherit"
                     size="small">
                     Sign Out
                  </Button>
               </Toolbar>
            </AppBar>
            <Drawer
               variant="permanent"
               classes={{
                  paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
               }}
               open={this.state.open}
            >
               <div className={classes.toolbarIcon}>
                  <IconButton onClick={this.handleDrawerClose}>
                     <ChevronLeftIcon/>
                  </IconButton>
               </div>
               <Divider/>
               <List>
                  <ListItem button onClick={this.goToPage(`photos`).bind(this)}>
                     <ListItemIcon>
                        <PhotoIcon/>
                     </ListItemIcon>
                     <ListItemText primary="Photos"/>
                  </ListItem>
                  <ListItem button onClick={this.goToPage(`collections`).bind(this)}>
                     <ListItemIcon>
                        <DashboardIcon/>
                     </ListItemIcon>
                     <ListItemText primary="Collections"/>
                  </ListItem>
                  <ListItem button onClick={this.goToPage(`home`).bind(this)}>
                     <ListItemIcon>
                        <HomeIcon/>
                     </ListItemIcon>
                     <ListItemText primary="Home Page"/>
                  </ListItem>
                  <ListItem button onClick={this.goToPage(`contact`).bind(this)}>
                     <ListItemIcon>
                        <ContactMailIcon/>
                     </ListItemIcon>
                     <ListItemText primary="Contact"/>
                  </ListItem>
                  <ListItem button onClick={this.goToPage(`about`).bind(this)}>
                     <ListItemIcon>
                        <ContactMailIcon/>
                     </ListItemIcon>
                     <ListItemText primary="About"/>
                  </ListItem>
               </List>

            </Drawer>
            <main className={classes.content}>
               <div className={classes.appBarSpacer}/>
               {pageToComponent[this.state.page]}
            </main>
         </div>
      );
   }
}

Dashboard
   .propTypes = {
   classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);