import React, {Component} from "react"
import {graphql} from 'gatsby'
import {CognitoUserPool} from 'amazon-cognito-identity-js';
import AdminTools from "../components/admin/admin-tools";
import SignIn from "../components/admin/sign-in";
import path from "path";
import _ from "lodash";
import {SnackbarProvider} from 'notistack';

export default class Admin extends Component {
    constructor(props) {
        super(props);

        const poolData = {
            UserPoolId: 'us-west-2_VmkWVI9Tr', // Your user pool id here
            ClientId: 'usvkregve1klmupcfo9hkmvkf' // Your client id here
        };

        const photoOrder = props.data.allFile.edges[0].node.childPhotosPageDataJson.list.map((row) => row.name + ".md");

        const nameToFluidPhoto = props.data.allMarkdownRemark.edges.reduce((acc, curr) => {
            if (curr.node.fields) {
                acc[path.basename(curr.node.fileAbsolutePath)] = curr.node.fields.image.childImageSharp.fluid;
            }
            return acc;
        }, {});

        this.state = {
            loggedIn: false,
            userPool: new CognitoUserPool(poolData),
            accessToken: undefined,
            idToken: undefined,
            photoOrder,
            nameToFluidPhoto
        };
    }

    componentDidMount() {
        var cognitoUser = this.state.userPool.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.getSession((err, session) => {
                const loggedIn = session && session.isValid();
                const idToken = session ? session.idToken.jwtToken : undefined;
                this.setState({loggedIn, idToken});
                if (err) {
                    alert(err);
                }
            });
        }
    }

    onLogoutClicked() {
        this.state.userPool.getCurrentUser().signOut();
        this.setState({loggedIn: false});
    }

    onLoggedIn(tokens) {
        this.setState({
            loggedIn: true,
            accessToken: tokens.accessToken,
            idToken: tokens.idToken
        });
    }

    onPhotoDeleted(fileName) {
        const photoOrder = _.difference(this.state.photoOrder, [fileName]);
        this.setState({photoOrder});
    }

    render() {
        return (
            <SnackbarProvider maxSnack={5}>
                <div>
                    {
                        this.state.loggedIn
                            ? <AdminTools photoOrder={this.state.photoOrder}
                                          nameToFluidPhoto={this.state.nameToFluidPhoto}
                                          onLogoutClicked={this.onLogoutClicked.bind(this)}
                                          onPhotoDeleted={this.onPhotoDeleted.bind(this)}
                                          idToken={this.state.idToken}/>
                            : <SignIn userPool={this.state.userPool}
                                      onLoggedIn={this.onLoggedIn.bind(this)}/>
                    }
                </div>
            </SnackbarProvider>
        )
    }
}

export const query = graphql`
  fragment Images on MarkdownRemarkConnection {
    edges {
        node {
           fileAbsolutePath
           frontmatter {
               title
               file
             }
             fields {
                  image {
                   childImageSharp {
                     fixed(width: 300, height: 300, quality: 100) {
                        ...GatsbyImageSharpFixed
                     }
                     fluid(maxWidth: 800, quality: 100) {
                     # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                        ...GatsbyImageSharpFluid
                     }
                   }
                 }
             }
          
        }
     }
  }
`

export const adminPageQuery = graphql`
    query AdminPage {
        allMarkdownRemark {
          ...Images
        }
        allFile(filter: { name: { eq : "order" }})  {
          edges {
            node {
              childPhotosPageDataJson {
                list {
                  name
                }
              }
            }
          }
        }   
      }
`