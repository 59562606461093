import React, {Component} from "react"
import Dashboard from "./dashboard";
import {Location} from "@reach/router";

export default class AdminTools extends Component {
   render() {
      return (
         <Location>
            {locationProps =>
               <Dashboard
                  {...locationProps}
                  idToken={this.props.idToken}
                  photoOrder={this.props.photoOrder}
                  nameToFluidPhoto={this.props.nameToFluidPhoto}
                  onLogoutClicked={this.props.onLogoutClicked}
                  onPhotoDeleted={this.props.onPhotoDeleted}
               />
            }
         </Location>
      )
   }
}
