import React, {Component} from "react"
import Typography from "@material-ui/core/Typography/Typography";
import AddPhotosDialog from "./add-photos-dialog";
import UploadImagesDialog from "./upload-images-dialog";
import DragDropPhotos from "./drag-drop-photos";
import ReorderIcon from '@material-ui/icons/Reorder';
import EditIcon from '@material-ui/icons/Edit';
import Button from "@material-ui/core/Button";
import UpdatePhotos from "./update-photos";
import { withSnackbar } from 'notistack';

class EditPhotos extends Component {
   constructor(props) {
      super(props);

      this.state = {
         subTab: 'update'
      };
   }

   onSubTabClicked = (subTab) => () => {
     this.setState({subTab});
   }

   onAddPhotosClicked(files) {
      this.setState({uploadingImages: true, imagesToUpload: files});
   }

   onFinishUploadPhotos() {
       this.setState({uploadingImages: false});
   }

   onImageUploadedSuccessfully(imageName) {
      this.props.enqueueSnackbar(`Successfully Uploaded ${imageName}`);
   }

   render() {
      return (
         <div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
               <Typography variant="h4" gutterBottom component="h2">
                  Photos
               </Typography>

               <AddPhotosDialog onAddPhotosClicked={this.onAddPhotosClicked.bind(this)}/>

               <Button style={{marginLeft: '15px'}} onClick={this.onSubTabClicked('update').bind(this)}>
                  <EditIcon/>
               </Button>

               <Button style={{marginLeft: '15px'}} onClick={this.onSubTabClicked('reorder').bind(this)}>
                  <ReorderIcon/>
               </Button>


               {this.state.uploadingImages && <UploadImagesDialog
                   imageFiles={this.state.imagesToUpload}
                   uploadingImages={this.state.uploadingImages}
                   onImageUploadedSuccessfully={this.onImageUploadedSuccessfully.bind(this)}
                   onFinishUploadPhotos={this.onFinishUploadPhotos.bind(this)}
                   idToken={this.props.idToken}/>}
            </div>

            {this.state.subTab === 'reorder' && <DragDropPhotos
                idToken={this.props.idToken}
                photoOrder={this.props.photoOrder}
                nameToFluidPhoto={this.props.nameToFluidPhoto}
            />}

            {this.state.subTab === 'update' && <UpdatePhotos
                onPhotoDeleted={this.props.onPhotoDeleted}
                idToken={this.props.idToken}
                photoOrder={this.props.photoOrder}
                nameToFluidPhoto={this.props.nameToFluidPhoto}
            />}

         </div>
      )
   }
}

export default withSnackbar(EditPhotos);
