import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {DropzoneDialog} from "material-ui-dropzone";

export default class AddPhotosDialog extends React.Component {
   state = {
      open: false,
   };

   handleClickOpen = () => {
      this.setState({ open: true });
   };

   handleClose = () => {
      this.setState({ open: false });
   };

   dontHandleClose = () => {
      console.log("hehe");
   };

   handleSave = (files) => {
      this.handleClose();
      this.props.onAddPhotosClicked(files);
   };

   render() {
      return (
         <div>
            <Button style={{marginLeft: '15px'}} onClick={this.handleClickOpen}>
               <AddIcon/>
            </Button>

            <DropzoneDialog
               open={this.state.open}
               onSave={this.handleSave.bind(this)}
               acceptedFiles={['image/jpeg', 'image/jpg', 'image/png', 'image/bmp']}
               showPreviews={true}
               filesLimit={20}
               maxFileSize={5000000}
               onClose={this.handleClose.bind(this)}
            />


         </div>
      );
   }
}